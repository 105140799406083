import React from 'react'
import Header from './header.jsx';
import { useTranslation } from 'react-i18next';

export default function Prayars() {
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <div dir="rtl" className=' container bg-light-subtle border rounded my-4'>
                <h2 className='my-4 main_text_color'>{t('prayars.title')}</h2>
                <p>כשמביאים את התינוק למול אומרים הקהל:</p>
                <p><strong>בָּרוּךְ הַבָּא:</strong></p>
                <p>והאב אומר:</p>
                <p><strong>אַשְׁרֵי תִּבְחַר וּתְקָרֵב יִשְׁכֹּן חֲצֵרֶיךָ:</strong></p>
                <p>והעומדים שם אומרים:</p>
                <p><strong>נִשְׂבְּעָה בְּטוּב בֵּיתֶךָ. קְדשׁ הֵיכָלֶךָ:</strong></p>
                <p>האב מקבל את התינוק ועומד כלפי ארון קודש ואומר (בלחש):</p>
                <p><strong>אִם אֶשְׁכָּחֵךְ יְרוּשָׁלִָֹם, תִּשְׁכַּח יְמִינִי:</strong></p>
                <p><strong>תִּדְבַּק לְשׁוֹנִי לְחִכִּי אִם לֹא אֶזְכְּרֵכִי, אִם לֹא אַעֲלֶה אֶת יְרוּשָׁלִַֹם עַל רֹאשׁ שִׂמְחָתִי (תהלים קלז):</strong></p>
                <p>ואחר כך אומר בקול רם והקהל עונה אחריו:</p>
                <p><strong>שְׁמַע יִשְׂרָאֵל יְהֹוָה אֱלֹהֵינוּ יְהֹוָה אֶחָד:</strong></p>
                <br></br>
                <p><strong>יְהֹוָה מֶלֶךְ יְהֹוָה מָלָךְ יְהֹוָה יִמְלֹךְ לְעוֹלָם וָעֶד</strong></p>
                <p><strong>יְהֹוָה מֶלֶךְ יְהֹוָה מָלָךְ יְהֹוָה יִמְלֹךְ לְעוֹלָם וָעֶד:</strong></p>
                <br></br>
                <p><strong>אָנָּא יְהֹוָה הוֹשִׁיעָה נָּא</strong></p>
                <p><strong>אָנָּא יְהֹוָה הוֹשִׁיעָה נָּא:</strong></p>
                <br></br>
                <p><strong>אָנָּא יְהֹוָה הַצְלִיחָה נָּא</strong></p>
                <p><strong>אָנָּא יְהֹוָה הַצְלִיחָה נָּא:</strong></p>
                <p>מעבירים את התינוק לכיסא של אליהו ואומרים:</p>
                <p><strong>זֶה הַכִּסֵּא שֶׁל אֵלִיָּהוּ הַנָּבִיא זָכוּר לַטּוֹב</strong></p>
                <p><strong>לִישׁוּעָתְךָ קִוִּיתִי יְהֹוָה</strong></p>
                <p><strong>שִׂבַּרְתִּי לִישׁוּעָתְךָ יְהֹוָה. וּמִצְוֹתֶיךָ עָשִׂיתִי</strong></p>
                <p><strong>אֵלִיָּהוּ מַלְאַךְ הַבְּרִית. הִנֵּה שֶׁלְּךָ לְפָנֶיךָ. עֲמוֹד עַל יְמִינִי וְסָמְכֵנִי. שִׂבַּרְתִּי לִישׁוּעָתְךָ יְהֹוָה. שָׂשׂ אָנֹכִי עַל אִמְרָתֶךָ. כְּמוֹצֵא שָׁלָל רָב. שָׁלוֹם רָב לְאֹהֲבֵי תוֹרָתֶךָ. וְאֵין לָמוֹ מִכְשׁוֹל. אַשְׁרֵי תִּבְחַר וּתְקָרֵב יִשְׁכֹּן חֲצֵרֶיךָ. נִשְׂבְּעָה בְּטוּב בֵּיתֶךָ. קְדשׁ הֵיכָלֶךָ:</strong></p>
                <p><strong>בָּרוּךְ אַתָּה יְהֹוָה אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם. אֲשֶׁר קִדְּשָׁנוּ בְּמִצְוֹתָיו. וְצִוָּנוּ עַל הַמִּילָה:</strong></p>
                <p>ואבי הבן אומר:</p>
                <p><strong>בָּרוּךְ אַתָּה יְהֹוָה אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם. אֲשֶׁר קִדְּשָׁנוּ בְּמִצְוֹתָיו. וְצִוָּנוּ לְהַכְנִיסוֹ בִּבְרִיתוֹ שֶׁל אַבְרָהָם אָבִינוּ:</strong></p>
                <p><strong>בָּרוּךְ אַתָּה יְהֹוָה אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם. שֶׁהֶחֱיָנוּ וְקִיְּמָנוּ וְהִגִּיעָנוּ לַזְמַן הַזֶּה:</strong></p>
                <p>והקהל עונה:</p>
                <p><strong>אָמֵן. כְּשֵׁם שֶׁנִּכְנַס לַבְּרִית. כֵּן יִכָּנֵס לְתוֹרָה וּלְחֻפָּה וּלְמַעֲשִׂים טוֹבִים:</strong></p>
                <p>ברכות לאחר הברית:</p>
                <p><strong>בָּרוּךְ אַתָּה יְהֹוָה אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם. בּוֹרֵא פְּרִי הַגָּפֶן:</strong></p>
                <p><strong>בָּרוּךְ אַתָּה יְהֹוָה אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם. אֲשֶׁר קִדַּשׁ יְדִיד מִבֶּטֶן. וְחוֹק בִּשְׁאֵרוֹ שָׂם. וְצֶאֱצָאָיו חָתַם בְּאוֹת בְּרִית קֹדֶשׁ. עַל כֵּן בִּשְׂכַר זֹאת. אֵל חַי חֶלְקֵנוּ צוּרֵנוּ. צַוֵּה (נ"א צִוָה) לְהַצִּיל יְדִידוּת שְׁאֵרֵנוּ מִשַּׁחַת. לְמַעַן בְּרִיתוֹ אֲשֶׁר שָׂם בִּבְשָׂרֵנוּ. בָּרוּךְ אַתָּה יְהֹוָה כּוֹרֵת הַבְּרִית:</strong></p>
                <p><strong>אֱלֹהֵינוּ וֵאלֹהֵי אֲבוֹתֵינוּ. קַיֵּם אֶת הַיֶּלֶד הַזֶּה לְאָבִיו וּלְאִמּוֹ. וְיִקָּרֵא שְׁמוֹ בְּיִשְׂרָאֵל (פלוני בן פלוני) יִשְׂמַח הָאַב בְּיוֹצֵא חֲלָצָיו. וְתָגֵל אִמּוֹ בִּפְרִי בִטְנָהּ. כַּכָּתוּב. יִשְׂמַח אָבִיךָ וְאִמֶּךָ וְתָגֵל יוֹלַדְתֶּךָ:</strong></p>
                <p><strong>וְנֶאֱמַר. וָאֶעֱבוֹר עָלַיִךְ וָאֶרְאֵךְ מִתְבּוֹסֶסֶת בְּדָמָיִךְ. וְאֹמַר לָךְ בְּדָמָיִךְ חֲיִי. וָאֹמַר לָךְ בְּדָמָיִךְ חֲיִי. וְנֶאֱמַר. זָכַר לְעוֹלָם בְּרִיתוֹ. דָּבָר צִוָּה לְאֶלֶף דּוֹר: אֲשֶׁר כָּרַת אֶת אַבְרָהָם וּשְׁבוּעָתוֹ לְיִשְׂחָק: וַיְּעֲמִידֶהָ לְיַעֲקֹב לְחֹק. לְיִשְׂרָאֵל בְּרִית עוֹלָם: וְנֶאֱמַר. וַיָּמָל אַבְרָהָם אֶת יִצְחָק בְּנוֹ בֶּן שְׁמוֹנַת יָמִים. כַּאֲשֶׁר צִוָּה אֹתוֹ אֱלֹהִים:</strong></p>
                <p><strong>הוֹדוּ לַיהוָה כִּי טוֹב. כִּי לְעוֹלָם חַסְדוֹ:</strong></p>
                <p><strong>הוֹדוּ לַיהוָה כִּי טוֹב. כִּי לְעוֹלָם חַסְדוֹ:</strong></p>
                <p><strong>(פלוני בן פלוני יזכיר שם הילד) זֶה הַקָּטֹן גָּדוֹל יִהְיֶה. כְּשֵׁם שֶׁנִּכְנַס לַבְּרִית. כֵּן יִכָּנֵס לְתוֹרָה וּלְחֻפָּה וּלְמַעֲשִׂים טוֹבִים:</strong></p>
            </div>
        </>
    )
}
